import type { ClinicalTrialEligibilityCriteria } from '@prisma/client'

export type EligibilityCriteriaForFormatting = Pick<
  ClinicalTrialEligibilityCriteria,
  | 'assertiveSummary'
  | 'authoringTrialProId'
  | 'criterion'
  | 'deletedAt'
  | 'id'
  | 'isInclusion'
  | 'isPreScreenerEnabled'
  | 'isMajor'
  | 'isRequiredForQualification'
  | 'nctId'
  | 'overrideAt'
  | 'questionSummary'
  | 'tags'
  | 'type'
  | 'updatedAt'
>

/**
 * Determine if we should show this eligibility criterion.
 *
 * @param criterion Eligibility criterion to determine visibility
 */
export default function isVisibleCriterion({
  criterion,
  isInclusion = true,
  isTrialProAdded,
  isUsedInMta = false,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isInclusion' | 'isMajor' | 'isPreScreenerEnabled' | 'tags'
  >
  isInclusion?: boolean
  isTrialProAdded: boolean
  isUsedInMta?: boolean
}) {
  if (isTrialProAdded) {
    return isVisibleCriterionManagedTrial({ criterion, isUsedInMta })
  }
  return isVisibleCriterionNonManagedTrial({ criterion, isInclusion })
}

export function isVisibleCriterionManagedTrial({
  criterion,
  isUsedInMta = false,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'isPreScreenerEnabled' | 'deletedAt'
  >
  isUsedInMta?: boolean
}) {
  if (isUsedInMta) {
    return criterion.deletedAt === null
  }
  return criterion.isPreScreenerEnabled && criterion.deletedAt === null
}

export function isVisibleCriterionNonManagedTrial({
  criterion,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isMajor' | 'isInclusion' | 'tags'
  >
  isInclusion: boolean
}) {
  // only show criterion for non-managed that is isMajor and not deletedAt
  const isValid = isValidCriterion({ allowedTags: ['other'], criterion })
  return criterion.isMajor && criterion.deletedAt === null && isValid
}

/**
 * Determine if we should show this eligibility criteria question given tags.
 *
 * @param criterion Eligibility criterion to determine visibility
 */
export function isValidCriterion({
  allowedTags = ['other'],
  criterion,
}: {
  allowedTags?: string[]
  criterion: Pick<EligibilityCriteriaForFormatting, 'tags'>
}) {
  const loweredAllowedTags = allowedTags.map((tag) => tag.toLowerCase())
  const hasValidTags =
    criterion.tags.length === 0 ||
    criterion.tags.some((t) => loweredAllowedTags.includes(t.toLowerCase()))

  return hasValidTags
}

export function isTrialProAdded({
  eligibilityCriteria,
}: {
  eligibilityCriteria: Pick<
    ClinicalTrialEligibilityCriteria,
    'authoringTrialProId' | 'overrideAt'
  >[]
}) {
  return eligibilityCriteria.some(
    (criterion) =>
      Boolean(criterion.authoringTrialProId) || Boolean(criterion.overrideAt),
  )
}
